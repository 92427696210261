import config from "../config";
import BaseService from "./BaseService";

class ApiService extends BaseService {
  async login(data) {

    const res = await this.call("post", `${config.backendUrl}/login`, data);
    return res.data ? res.data : {};
  }
  async list(data) {
    const res = await this.call("get", `${config.backendUrl}/customers`, data);
    return res.data ? res.data : {};
  }
}
export default ApiService;
