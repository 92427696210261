const styles = {
    global: {
        palette: {
            primary: {
                main: "#3F0582",
            },
            secondary: {
                main: "#FF0080",
            },
        },
        typography: {
            fontFamily: "Prompt",
        },
    },
    custom: {
        main(theme) {
            return {
                paper: {
                    marginTop: theme.spacing(8),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  },
                  avatar: {
                    margin: theme.spacing(1),
                    backgroundColor: theme.palette.secondary.main,
                  },
                  form: {
                    width: '100%', // Fix IE 11 issue.
                    marginTop: theme.spacing(1),
                  },
                  submit: {
                    margin: theme.spacing(3, 0, 2),
                  },
                  title: {
                    flexGrow: 1,
                  },
                  button:{
                    padding:theme.spacing(2),
                  }
 
            };
        },

    },
};

export default styles;
