import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from '../styles';
import history from '../history';
import MaterialTable from 'material-table'
import { Grid } from '@material-ui/core';
import AlertMessage, { useAlertMessage } from './AlertMessage';
import ApiService from '../services/ApiService'

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};


const prepareShpeData = (dataArray) => {
  const dataRows = []
  for (let index = 0; index < dataArray.length; index += 1) {
    const dataObj = dataArray[index];
    dataRows.push(
      {
        _id: `${dataObj._id}`,
        createAt: `${dataObj.createAt}`,
        frontLeftWheel: `ล้อหน้าซ้าย : ${dataObj.frontLeftWheel}`,
        backLeftWheel: `ล้อหลังซ้าย : ${dataObj.backLeftWheel}`,
        frontRightWheel: `ล้อหน้าขวา : ${dataObj.frontRightWheel}`,
        backRightWheel: `ล้อหลังขวา : ${dataObj.backRightWheel}`,
        vehicleRegistration: `${dataObj.vehicleRegistration}`,
        province: `จังหวัด : ${dataObj.province}`,
        brand: `ยี่ห้อ : ${dataObj.brand}`,
        generation: `รุ่น : ${dataObj.generation}`,
        name: `${dataObj.firstName} ${dataObj.lastName}`,
        phoneNumber: `${dataObj.phoneNumber}`,
        imageMileageUrl: `${dataObj.imageMileageUrl}`,
        imageReceiptUrl: `${dataObj.imageReceiptUrl}`,
        facebook: `facebook : ${dataObj.user.name}`,
        email: `อีเมล : ${dataObj.email}`,
        dealerInfo: `${dataObj.dealer.dealerName}`,
        dealerProvince: `จังหวัด : ${dataObj.dealer.dealerProvince} `,
      })
  }
  return dataRows
}

const useStyles = makeStyles(styles.custom.main);

const ListPage = (props) => {
  const [messageAlert, closeMessage, showMessage] = useAlertMessage();
  const handleError = (e) => {
    const errorMessage = e.response ? e.response.data.error : e.message;
    showMessage(errorMessage, 'error');
  };
  const Authorized = () => {
    localStorage.clear()
    history.push('/');
  };
  const classes = useStyles();
  const [customers, setCustomers] = useState([])
  const logout = () => {
    localStorage.clear()
    history.push('/');
  }
  const token = localStorage.getItem('token')
  const apiService = new ApiService(token, { onError: handleError, onUnAuthorized: Authorized });
  const vertify = () => {
    if (!token) {
      history.push('/');
    }

  };
  let dataRows = prepareShpeData(customers)


  useEffect(() => {
    const getCustomers = async () => {
      const customers = await apiService.list()
      if (customers) {
        setCustomers(customers)
      }
    }
    vertify()
    getCustomers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (
    <React.Fragment>
      <AlertMessage messageAlert={messageAlert} onClose={closeMessage} />
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>รายการลงทะเบียนทั้งหมด</Typography>
            <Button color="inherit" onClick={e => logout()}>ออกระบบ</Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      <BasicTreeData />




    </React.Fragment>
  )

  function BasicTreeData() {
    return (
      <MaterialTable
        title="รายชื่อทั้งหมด"
        columns={[
          { title: 'วันที่ลงทะเบียน', field: 'createAt' },
          { title: 'ชื่อลูกค้า', field: 'name' },
          { title: 'เบอร์โทร', field: 'phoneNumber' },
          { title: 'ทะเบียน', field: 'vehicleRegistration' },
          { title: 'จังหวัด', field: 'province', hidden: true, export: true },
          { title: 'ยี่ห้อ', field: 'brand', hidden: true, export: true },
          { title: 'รุ่น', field: 'generation', hidden: true, export: true },
          { title: 'ล้อหน้าซ้าย', field: 'frontLeftWheel', hidden: true, export: true },
          { title: 'ล้อหลังซ้าย', field: 'backLeftWheel', hidden: true, export: true },
          { title: 'ล้อหน้าขวา', field: 'frontRightWheel', hidden: true, export: true },
          { title: 'ล้อหลังขาว', field: 'backRightWheel', hidden: true, export: true },
          { title: 'ตัวแทนจำหน่าย', field: 'dealerInfo', export: true },
          { title: 'จังหวัดของตัวตัวแทนจำหน่าย', field: 'dealerProvince', hidden: true, export: true },
          { title: 'อีเมล', field: 'email', hidden: true, export: true },
          { title: 'facebook', field: 'facebook', hidden: true, export: true },

        ]}
        data={dataRows}
        options={{

          pageSize: 10,
          rowStyle: {
            verticalAlign: "-webkit-baseline-middle"
          },

          exportButton: {
            csv: true,
            pdf: false
          }
        }}


        detailPanel={rowData => {
          return (
            <div style={{ margin: 20 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} >
                  <Typography variant="subtitle1" style={{ paddingTop: 10 }} >รหัสรับประกัน</Typography>
                  <Grid >
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.frontLeftWheel}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.backLeftWheel}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.frontRightWheel}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.backRightWheel}</Typography>
                  </Grid >
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="subtitle1" style={{ paddingTop: 10 }} >ข้อมูลรถยนต์</Typography>
                  <Grid >
                    <Typography variant="body2" style={{ paddingTop: 10 }} >ทะเบียน : {rowData.vehicleRegistration}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.province}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }}>{rowData.brand}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.generation}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="subtitle1" style={{ paddingTop: 10 }} >ข้อมูลลูกค้า</Typography>
                  <Grid >
                    <Typography variant="body2" style={{ paddingTop: 10 }} >ชื่อ-นามสกุล : {rowData.name}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >เบอร์โทร : {rowData.phoneNumber}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }}>{rowData.email}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.facebook}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="subtitle1" style={{ paddingTop: 10 }} >ตัวแทนจำหน่อย</Typography>
                  <Grid >
                    <Typography variant="body2" > ร้าน : {rowData.dealerInfo}</Typography>
                    <Typography variant="body2" style={{ paddingTop: 10 }} >{rowData.dealerProvince}</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2}  >
                  <Typography variant="subtitle1" style={{ paddingTop: 10 }} >รูปภาพ</Typography>
                  <Grid style={{ display: "flex", margin: 5 }} >
                    <Typography variant="body2" style={{ paddingTop: 10 }}>
                      <a href={`${rowData.imageMileageUrl}`} target="_blank" rel="noreferrer">
                        <img src={`${rowData.imageMileageUrl}`} alt="Image Mileage" width="100" height="100" />
                      </a>
                    </Typography>


                    <Typography variant="body2" style={{ paddingTop: 10, paddingLeft: 10 }}>
                      <a href={`${rowData.imageReceiptUrl}`} target="_blank" rel="noreferrer">
                        <img src={`${rowData.imageReceiptUrl}`} alt="Image Receipt" width="100" height="100" />
                      </a>
                    </Typography>


                  </Grid>
                </Grid>

              </Grid></div>
          )
        }}
      />
    )
  }


}
export default ListPage;
