import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import AlertMessage, { useAlertMessage } from './AlertMessage';
import Button from '@material-ui/core/Button';
import LoginService from '../services/ApiService'
import { Redirect } from "react-router-dom";
import history from '../history'
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {'Nos-N1 '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1)
  },

}));

export default function LoginPage() {
  const classes = useStyles();
  const [messageAlert, closeMessage, showMessage] = useAlertMessage();

  const handleError = (e) => {
    const errorMessage = e.response ? e.response.data.error : e.message;
    showMessage(errorMessage, 'error');
  };
  const Authorized = () => {
    return;
  };
  const loginService = new LoginService('', { onError: handleError, onUnAuthorized: Authorized });
  const [username, setUsername] = useState(String);
  const [password, setPassword] = useState(String);
  const token = localStorage.getItem("token");
  if (token) {
    return <Redirect to="/list" />;
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const dataLogin = {
      username: username,
      password: password,
    };

    const res = await loginService.login(dataLogin)
    if (!res.token) {
      return;
    }
    localStorage.setItem("token", res.token);
    return history.push("/list");
  };

  return (
    <Container component="main" maxWidth="xs">
      <AlertMessage messageAlert={messageAlert} onClose={closeMessage} />
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleOnSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="username"
            autoComplete="useranme"
            autoFocus
            type='username'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className={classes.submit}

          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
