import axios from "axios";

class BaseService {
  constructor(token, callbacks) {
    this.axios = axios.create({});
    if (token) {
      this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    this.callbacks = callbacks;
  }

  async call(method, url, data, options) {
    try {
      if (["get", "post", "put", "delete"].indexOf(method) === -1) {
        throw new Error(`Method "${method}" not supported.`);
      }

      return await this.axios({
        method,
        url,
        data,
        ...options,
      });
    } catch (e) {
      if (e.response && e.response.status === 401) {
        this.callbacks.onUnAuthorized(e);
        return { data: undefined };
      }

      this.callbacks.onError(e);
      return { data: undefined };
    }
  }
}

export default BaseService;
